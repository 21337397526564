.chat_background{
    min-height: 92vh;
    position: relative;
    background: url("../assets/gradient.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.chat_background::after{
    content: "";
    background: url("../assets/chatbackground.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.root_chat{
    display: flex;  
    justify-content: center;
}

.navbar{
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(43 43 43 / 33%);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.navbar svg{
    cursor: pointer;
}
.navbar img{
    padding: 20px;
    width: 170px;
}

.chat{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
    margin-top: -2vh;
}

.info{
    text-align: center;
    font-size: 18px;
}
.info h4{
    font-style: italic;
    font-weight: normal;
    color: gray;
}
.info span{
    color:#5CE1E6;
}

.chat-form{
    display: flex;
    flex-direction: column;
    bottom: 5vh;
    position: fixed;
    width: 85%;
    gap: 10px;
}

.chat-form div{
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 78px;
}

.chat-form button{
    color: black;
    background-color: #D9D9D9;
    border: none;
    border-radius: 23px;
    padding: 13px 20px 12px 20px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 80px;
    max-height: 90px;
}

.chat-form textarea{
    width: 95%;
    padding: 15px 95px 15px 15px;
    font-size: 18px;
    border: none;
    border-radius: 23px 50px 50px 23px;
    resize: none;
    background-color: #D9D9D9;
    color: black;
    font-family: 'Inter', sans-serif;
    outline: none;
}

.chat-form textarea:focus{
    box-shadow: 0 0 0 2px #626262;
}

.chat-form button svg{
    font-size: 33px;
}

.chat-form button p{
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.chat-form button span{
    font-size: 18px;
}

.chat-history{
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    width: 100%;
    height: 60vh;
}

.chat-message{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 3px;
    padding: 5px;
    position: relative;
}

.chat-history .user{
    justify-content: flex-end;
}

.chat-history .stranger{
    justify-content: flex-start;
}

.chat-message-text{
    background-color: #D9D9D9;
    padding: 16px;
    font-size: 19px;
    color: black;
    font-family: 'Inter', sans-serif;
    max-width: 70%;
    word-wrap: break-word;
    margin: 0px 10px 0px 10px;
    max-width: 40%;
    border-radius: 23px 0px 23px 23px;
}

.st{
    background-color: #81F7FB;
    border-radius: 0px 23px 23px 23px;
}

.loading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loading img{
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.skip{
    padding: 2.5px 6px 3.5px 6px!important;
    gap: 0px!important;
}

.typing{
    visibility: visible!important;
    opacity: 1!important;
}

.end{
    justify-content: center!important;
}

.popup p h2{
    font-size: 30px;
}

.send-icon{
    width: 35px;
    height: 45px;
    bottom: 1.5px;
    color: #5CE1E6;
    cursor: pointer;
    background-color: #5CE1E6;
    padding: 15px 15px 15px 20px;
    border-radius: 50%; 
    position: absolute;
    right: 0;
}

#chat-input::-webkit-scrollbar {
    display: none;
}

.interests{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.interests > div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: url("../assets/gradient.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 55px;
    box-shadow: -1px 2px 60px -10px #81F7FB;
    padding: 20px;
    gap: 15px;
    position: relative;
    max-width: 80%;
}

.interests > div > div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.interests > div > div > p{
    max-width: 80%;
    margin-top: -8px;
}

.interests > div > div > h2 > span{
    color: #81F7FB;
}
.interests button{
    position: absolute;
    bottom: 15px;
    right: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 15px;
    font-weight: 900;
    background-color: #1B0140;
    border: none;
    border-radius: 30px;
    padding: 4px 10px;
    cursor: pointer;
    box-shadow: 0px 0px 3px 0px #000000 inset;
    color: white;
}
@media screen and (max-width: 768px), screen and (max-width: 992px) {
    .chat_background{
        background-size: 200vh;
    }
    .send-icon{
        width: 30px;
        height: 36px;
        bottom: 0;
    }
    .not-background{
        background-color: #151515;
        background-image: unset;
    }
    .chat-form{
        bottom: 3vh;
        width: 90%;
    }
    .chat{
        margin-top: -8.5vh;
        width: 93%;
    }
    .navbar{
    }
    .navbar img{
        padding: 20px;
    }
    .chat-message-text{
        font-size: 16px;
        max-width: 60%;
    }
    
    .chat-form div{
        display: flex;
        flex-direction: row;
        gap: 10px;
        height: 66px;
    }
    
    .chat-form button{
        min-width: 66px;
    }
    
    .chat-form textarea{
        padding: 10px 65px 10px 10px;
        font-size: 16px;
    }
}


@media screen and (max-width: 772px){
    .interests > div > img{
        width: 50px;
    }
    .interests > div{
        border-radius: 60px;
    }
    .interests > div > div > h2{
        font-size: 18px;
    }
    .interests > div > div > p{
        font-size: 14px;
    }



}