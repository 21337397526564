.container{
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    width: 100%;
    height: 100%;
    position: absolute;
}

.popup{
    position: relative;
    top:4%;
    width: 60%;
    height: 94%;
    margin: auto;
    display: flex;
    justify-content: center;
    background: rgba(17, 5, 50, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(100px);
    border-radius: 97px;
    z-index: 2;
    min-width: 640px;
}

.popup p{
    font-size: 20px;
    margin-bottom: 20px;
    overflow: auto;
    text-align: center;
    padding: 0px 25px 25px 25px;
    border-radius: 97px;
}

.popup li{
    text-align: left;
}

.popup p span:first-child{
    font-size: 30px;
}
.popup p span{
    font-weight: 700;
    font-size: 25px;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #1B0140;
    border-radius: 10px;
    margin-top: 48px;
    margin-bottom: 48px;
    margin-right: 20px;
}

::-webkit-scrollbar-thumb {
    background: rgba(38, 2, 115, 0.7);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(38, 2, 115, 1);
}

@media screen and (max-width: 768px){
    .header {
        top: unset;
        left: unset;
        position: absolute;
        float: right;
        right: 50px;
        bottom: 45px;
    }
    .popup{
        width: 95%;
        height: 90%;
        top: 8%;
        padding: 4px;
        min-width: unset;
    }
}