html{
    height: 100%;
}
.loading_back{
    height: 100%;
    position: relative;
    background: url("../assets/gradient.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
.loading_back::after{
    content: "";
    background: url("../assets/chatbackground.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.audiochat_background{
    background: linear-gradient(129.47deg, #19154D 28.28%, #304DD5 56.71%, #56CADA 103.55%);

}
.header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    padding: 10px;
    margin: auto;
    margin-bottom: 25px;
}
.header svg{
    padding: 15px;
    cursor: pointer;
}
.container{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3px;
}
.container>div{
    width: 30vw;
    height: 68vh;
    min-width: 385px;
    max-height: 800px;   
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.59);

    position: relative;
}

.disconnected{
    background: rgba(83, 83, 83, 0.43)!important;
}

.container>div:first-of-type{
    border-radius: 30px 0px 0px 30px;
}
.container>div:last-of-type{
    border-radius: 0px 30px 30px 0px;
}

.container aside{
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.container aside div{
    padding: 4px;
    background-color: #D9D9D9;
    border-radius: 50%;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container aside div svg{
    width: 30px;
    height: 30px;
}

.container > div > div {
    background: black;
    padding: 50px;
    border-radius: 50%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.container > div > div > span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Press Start 2P", system-ui;
    font-weight: 400;
    font-style: normal;
    color: #F44A40;
    font-size: 14px;
}


.speaking {
    animation: pulse 1s infinite;
    border: 2px solid #5CE1E6;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(92, 225, 230, 0.7);
    }
    70% {
        box-shadow: 0 0 0 15px rgba(92, 225, 230, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(92, 225, 230, 0);
    }
}
.controls{
    display:flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -35px;
}
.controls>div{
    background-color: #1B0140;
    width: 34vw;
    min-width: 450px;
    border-radius: 50px;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 15px;
    z-index: 2;
}
.controls div button{
    background-color: #D9D9D9;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    padding: 8px;
    max-height: 56px;
    margin-top: 6px;
    user-select: none;
    -webkit-tap-highlight-color: transparent; 
}
.controls div button svg{
    width: 38px;
    height: 38px;
}
.skip{
    background-color: #EB5A51!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 20px!important;
    min-height: 88px!important;

    max-width: 90px!important;
    width: 90px;
    margin-top: -10px!important;
    margin-bottom: -10px!important;
}
.skip span{
    font-weight: bold;
    font-size: 17px;
    color: black;
}

.skip p{
    color: #1B0140;
}

.info{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1B0140;
    padding: 20px 5px 20px 8px;
    border-radius: 15px 0px 0px 15px;
    position: absolute;
    top: 43%;
    right: 1px;
    max-width: 30px;
    font-size: 15px;
}
.info>svg{
    font-size: 22px;
    padding-bottom: 3px;
}
.info span{
    font-weight: bold;
    color: #D9D9D9;
    overflow: hidden;
}

.pressed{
    background-color: #77fffd!important;
}

@media screen and (max-width: 772px){
    .header{
        width: 90%;
    }
    .header img{
        width: 150px;
    }
    .container>div{
        height: 35vh;
    }
    .container>div:first-of-type{
        border-radius: 30px 30px 0px 0px;
    }
    .container>div:last-of-type{
        border-radius: 0px 0px 30px 30px;
    }
    .container>div>div{
        padding: 30px;
    }
    .controls{
        margin-top: unset;
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .controls>div{
        width: 100%;
        min-width: unset;
        gap: 10px;
        border-radius: 30px 30px 0 0;
    }
    .controls div button{
        margin-top: 8px;
    }
    .skip{
        margin-bottom: 5px!important;
    }
    .header > svg:nth-child(3){
        margin-top: 10px!important;
    }
}
@media screen and (max-width: 400px){
    .container>div{
        min-width: 280px;
    }
    .header img{
        width: 120px;
    }
}

@media screen and (max-height: 788px){
    .header{
        margin-bottom: -10px;
        padding: 0px;
    }
}

.interests{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.interests > div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: url("../assets/gradient.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 55px;
    box-shadow: -1px 2px 60px -10px #81F7FB;
    padding: 20px;
    gap: 15px;
    position: relative;
    max-width: 80%;
}

.interests > div > div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.interests > div > div > p{
    max-width: 80%;
    margin-top: -8px;
}

.interests > div > div > h2 > span{
    color: #81F7FB;
}
.interests button{
    position: absolute;
    bottom: 15px;
    right: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 15px;
    font-weight: 900;
    background-color: #81F7FB;
    border: none;
    border-radius: 30px;
    padding: 4px 10px;
    cursor: pointer;
    box-shadow: 0px 0px 3px 0px #000000 inset;
    color: white;
}
@media screen and (max-width: 772px){
    .interests > div > img{
        width: 50px;
    }
    .interests > div{
        border-radius: 60px;
    }
    .interests > div > div > h2{
        font-size: 18px;
    }
    .interests > div > div > p{
        font-size: 12px;
    }
}