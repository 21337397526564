body{
    font-size: 16px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #ededed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    background: url("../assets/background.svg");
    background-attachment:fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
}
.main{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

/*START HEADER (OPEN AND CLOSE BUTTON)*/

.header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
}

.header svg {
    position: absolute;
    font-weight: 700;
    font-size: 60px;
    color:#5CE1E6;
    cursor: pointer;
    transition: transform 1s ease-out, opacity 2s ease-out, box-shadow 0.4s ease-in-out;
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    border-radius: 50%;
    background-color: #1B0140;
}

.header svg:hover{
    -webkit-box-shadow: 0px 0px 47px -14px rgba(92,225,230,0.97); 
    box-shadow: 0px 0px 47px -14px rgba(92,225,230,0.97);
}


.header .active {
    transform: rotate(360deg);
    opacity: 1;
    visibility: visible;
}

/*END HEADER*/

/*START HERO*/
.hero{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
    z-index: 0;
}
.logo{
    max-width: 420px;
    max-height: 116px;
}

.hero button{
    color: white;
    padding: 25px 55px;
    height: 198px;
    border: none;
    border-radius: 60%;
    word-spacing: 5px;
    font-size: 20px;
    font-weight: 700;
    background: radial-gradient(50% 50% at 50% 50%, #30587A 0%, #0F0126 100%);
    box-shadow: 0px 4px 61.6px rgba(129, 247, 251, 0.5);
    -webkit-box-shadow: 0px 4px 61.6px rgba(129, 247, 251, 0.5);
    transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent; 
}

.hero button:hover, .hero button:focus{
    -webkit-box-shadow: 0px 0px 47px -14px rgba(92,225,230,0.97); 
    box-shadow: 0px 0px 47px -14px rgba(92,225,230,0.97);
    transform: translateY(-5px);
}

.hero button:hover + .omini .omino {
    transform: translateY(-5px);
}
.hero button:focus + .omini .omino {
    transform: translateY(-5px);
}

.hero button p {
    transform: perspective(500px) rotateX(30deg);
    padding-bottom: 25px;
}
.hero button span{
    color: #5CE1E6;
}

.omini{
    display: flex;
    flex-direction: row;
    position: absolute;
}
.omino{
    width: 170px;
    position: relative;
    top: 120px;
    transition: transform 0.4s ease-in-out;
    user-select: none;
    -webkit-tap-highlight-color: transparent; 
}
.options{
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 500px;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
}



.options div{
    background: linear-gradient(118.19deg, #1B0140 33.41%, rgba(129, 247, 251, 0.99) 163.68%);
    box-shadow: 0px 4px 58.4px rgba(92, 225, 230, 0.6);
    -webkit-box-shadow: 0px 4px 58.4px rgba(92, 225, 230, 0.6);
    border-radius: 22px;
    width: 130px;
    height: 130px;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 0;
    user-select: none;
    -webkit-tap-highlight-color: transparent; 
}

.options div:hover span{
    color: #5CE1E6;
}


.options div span{
    font-weight: 700;
    font-size: 17px;
    transition: color 0.4s ease-in-out;
}

.options div img{
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.options div:hover{
    transform: scale(1.03);
}

.options > div:nth-child(1){
    border-top-right-radius: 0px;
}
.options > div:nth-child(2){
    border-top-left-radius: 0px;
}

/*END HERO*/

/*START BOTTOM*/

.bottom{
    position: absolute;
    bottom: 25px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
}

div.bottom > button{
    border:none;
    color: #ededed;
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    background: linear-gradient(219.57deg, #55C4DA -33.75%, #1B0140 68.12%);
    border-radius: 51px;
    padding: 8px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
}
div.bottom > button:hover{
    transform: scale(1.05);
}

.bottom .interest{
    display: none;
    flex-direction: row;
    padding: 0;
    margin-bottom: 20px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 0;
}

.bottom .interest input[type="text"]{
    width: 100%;
    padding: 20px 85px 20px 20px;
    font-size: 18px;
    border: none;
    border-radius: 36px;
    background-color: #D9D9D9;
    color: white;
    font-style: italic;
    font-family: 'Inter', sans-serif;
    outline: none;
    margin-left: 60px;
    background: rgba(0, 0, 0, 0.32);
}

.bottom .interest button{
    border: none;
    background: url("../assets/v.webp");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 40px;
    height: 40px;
    padding: 13px 20px 12px 20px;
    position: relative;
    left: -60px;
    top: 10px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}


.bottom .interest button:hover{
    transform: scale(1.05);
}

@media (min-height: 500px) {
    .hero {
        margin-top: 3vh;
    }
    .options{
        margin-top: -60px!important;
    }
    .bottom{
        bottom: 20px;
    }
}

@media (min-height: 750px) {
    .hero {
        margin-top: 10vh;
    }
    .options{
        margin-top: -40px!important;
    }
}

@media screen and (min-height: 1000px) {
    .hero {
        margin-top: 15vh;
    }
}

@media screen and (max-width: 768px){
    .header svg{
        top: 10px;
        left: 10px;
        font-size: 50px;
    }
    .hero{
        /* margin-top: 30px; */
    }
    .logo{
        max-width: 275px;
        max-height: 116px;
    }
    .hero button{
        max-width: 330px;
    }
    .omino{
        top: 100px;
    }
    .options{
        position: relative;
        top: unset!important;
        left: unset;
        flex-direction: column;
        gap: 0;
        transform: unset;
        z-index: 0;
        margin-top: -40px;

        background: linear-gradient(279.72deg, #29097E 6.23%, #150C2D 27.35%, #1B0051 83.03%);

        background-blend-mode: saturation;
        mix-blend-mode: normal;
        box-shadow: 0px 4px 56.9px rgba(92, 225, 230, 0.3);
        -webkit-box-shadow: 0px 4px 56.9px rgba(92, 225, 230, 0.3);
        border-radius: 36px;
    }
    .options::after{
        content: "";
        position: absolute;
        top: 50%;
        left: 5%;
        width: 90%;
        height: 1px;
        background: rgba(129, 247, 251, 0.22);
        border-radius: 10px;
    }
    .options div{
        position: static;
        width: 270px;
        height: 60px;
        background: unset;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        padding: 5px;

        box-shadow: none;
        -webkit-box-shadow: none;
    }
    .options div:hover{
        transform: none;
    }
    .options > div:nth-child(1){
        border-radius: 36px 36px 0px 0px;
    }

    .options > div:nth-child(2){
        border-radius: 0px 0px 36px 36px;
    }
    .options div img{
        width: 40px;
        height: 40px;
        margin-bottom: 0px;
        margin-left: 22px;
    }
    .options div span{
        margin-top: 2px;
        margin-left: 8px;
        font-size: 22px;
    }

    .options div span:active{
        color: #5CE1E6;
    }

    .bottom .interest{
        position: relative;
        padding: 0 8px;
    }
    .bottom .interest input[type="text"]{
        margin-left: 0;
    }
    .bottom .interest button{
        position: absolute;
        left: unset;
        right: 20px;
    }

}