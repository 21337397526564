/*this is the css for a popup*/
.reportPopUp{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("../assets/gradient.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    z-index: 1000;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 370px;
    aspect-ratio: 529/328;
    text-align: center;
    box-shadow: 0px 0px 76.8px rgba(129, 247, 251, 0.8);
    -webkit-box-shadow: 0px 0px 76.8px rgba(129, 247, 251, 0.8);
}

.blurFilter{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
}

.reportPopUp img{
    width: 60px;
    height: 60px;
}

.reportPopUp h2{
    color: white;
    font-size: 22px;
    font-weight: 800;
}
.reportPopUp h2 span{
    color: #81F7FB;
}
.reportPopUp p{
    color: white;
    font-weight: 300;
    font-size: 13px;
    font-style: italic;
    margin-top: -3px;
}
.reportPopUp div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.reportPopUp button{
    background-color: #EB5A51;
    color: white;
    font-weight: 900;
    font-family: 'Inter', sans-serif;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 20px;
    min-width: 80px;
    cursor: pointer;
    transition: all 0.3s;
}
.reportPopUp button:hover{
    transform: scale(1.05);
}
.reportPopUp button:last-of-type{
    background-color: #0AEBC2;
    color: #393838;
}

@media (max-width: 500px){
    .reportPopUp{
        width: 300px;
    }
    .reportPopUp h2{
        font-size: 20px;
    }
    .reportPopUp p{
        font-size: 12px;
    }
    .reportPopUp button{
        font-size: 18px;
    }
}
